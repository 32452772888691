<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h1>AQUI</h1>
    <div class="manualOrder">
      <h1>Criar Encomenda Manual</h1>
      <form v-on:submit.prevent="getProduct()">
        <div id="searchEan" class="form-group row">
          <label for="productEan" id="lblProductEan" class="col-sm col-form-label"><b>Cód. Produto:</b></label>
          <div class="col-sm">
            <input type="text" class="form-control" id="productEan" autofocus v-model="ean" />
          </div>
          <div class="col-sm">
            <button class="btn btn-primary">Adicionar</button>
          </div>
        </div>
      </form>
      <!-- <model-select
      ref="modelSelect"
      :class="[item === '' || isActive ? 'is-italic' : '']"
      :options="options1"
      v-model="item"
      placeholder="select item"
      :customAttr="test"
    ></model-select> -->
      <!-- <table class="table">
        <thead>
          <tr>
            <th scope="col">EAN</th>
            <th scope="col">SKU</th>
            <th scope="col">Nome</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in filter" :key="product.id">
            <td>{{ product.ean }}</td>
            <td>{{ product.sku }}</td>
            <td>
              <a
                id="openArticle"
                v-b-modal.modal-4
                @click="passProductData(product.sku)"
                >{{ product.name }}</a
              >
            </td>
            <td>
              <button
                type="button"
                class="btn btn-primary"
                @click="addProduct(product.id)"
              >
                +
              </button>
            </td>
          </tr>
        </tbody>
      </table> -->
      <!-- <span v-if="this.total > 1">
        <span v-if="this.currentPage == 1">
          <button
            @click="prevPage()"
            type="button"
            class="btn btn-success"
            disabled
          >
            Prev
          </button></span
        >
        <span v-else>
          <button @click="prevPage()" type="button" class="btn btn-success">
            Prev
          </button>
        </span>
        {{ currentPage }}/{{ total }}
        <span v-if="this.currentPage == this.total"
          ><button
            @click="nextPage()"
            disabled
            type="button"
            class="btn btn-success"
          >
            Next
          </button></span
        >
        <span v-else>
          <button @click="nextPage()" type="button" class="btn btn-success">
            Next
          </button></span
        >
      </span> -->
      <hr />
      <h3>Lista de Artigos</h3>
      <table class="table" id="newOrder">
        <thead>
          <tr>
            <th scope="col">SKU</th>
            <th scope="col">Nome</th>
            <th scope="col">Quantidade</th>
            <th scope="col">Preço</th>
            <th scope="col">Total</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(product, index) in orderProducts"
            :key="product.id"
            :style="product.sku == skuInfo ? { 'background-color': '#ff9393' } : { 'background-color': 'white' }"
          >
            <td>{{ product.sku }}</td>
            <td>{{ product.name }}</td>
            <td>
              <input type="text" class="form-control" v-model="orderProducts[index].quantity" />
            </td>
            <td>
              <input class="form-control" type="text" v-model="orderProducts[index].price" />
            </td>
            <td>
              {{
                (orderProducts[index].total =
                  Number(orderProducts[index].price) * Number(orderProducts[index].quantity))
              }}
            </td>
            <td>
              <button type="button" class="btn btn-danger" @click="removeProduct(product.id)">-</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <h5>
          Total:
          <b>
            {{
              this.orderProducts.reduce((sum, product) => {
                return sum + Number(product.total);
              }, 0)
            }}
          </b>
        </h5>
      </div>
      <hr />
      <h3>Dados do Cliente</h3>
      <div id="searchEan" class="form-group row">
        <div class="col-sm">
          <input
            id="previousID"
            type="text"
            class="form-control"
            @change="getOrderInfo()"
            autofocus
            v-model="previousID"
          />
        </div>
        <div class="col-sm">
          <button class="btn btn-primary" @click="getOrderInfo()">Pesquisa</button>
        </div>
      </div>
      <hr />
      <form v-on:submit.prevent="createOrder()">
        <div class="row">
          <div class="col">
            <!-- NOME DO DESTINATÁRIO -- NÃO EDITÁVEL -->
            <label for="firstName">*Primerio Nome:</label>
            <input id="firstName" type="text" class="form-control" v-model="firstName" required />
          </div>
          <div class="col">
            <!-- APELIDO DO DESTINATÁRIO -- NÃO EDITÁVEL -->
            <label for="secondName">Apelido:</label>
            <input id="secondname" type="text" class="form-control" v-model="secondName" />
          </div>
          <div class="col">
            <!-- EMAIL DO DESTINATÁRIO -- NÃO EDITÁVEL -->
            <label for="email">*Email:</label>
            <input id="email" type="text" class="form-control" v-model="email" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <!-- MORADA DO DESTINATÁRIO -- NÃO EDITÁVEL -->
            <label for="adress">*Morada:</label>
            <input id="adress" type="text" class="form-control" v-model="finalAdress" required />
          </div>
          <div class="col">
            <!-- CIDADE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
            <label for="city">*Cidade:</label>
            <input id="city" type="text" class="form-control" v-model="city" />
          </div>
          <div class="col">
            <label for="height">Estado:</label>
            <input id="height" type="text" class="form-control" v-model="state" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="country">*Código País:</label>
              <!-- <input
            id="country"
            type="text"
            class="form-control"
            v-model="country"
            style="text-transform: uppercase"
            required
          /> -->
              <v-select
                style="font-size: small"
                :required="!country"
                v-model="country"
                :options="countries"
                :reduce="(country) => country.code"
                label="name"
              >
                <template slot="option" slot-scope="option"> {{ option.name }} ({{ option.code }}) </template>
                <template slot="selected-option" slot-scope="option"> {{ option.name }} ({{ option.code }}) </template>
              </v-select>
            </div>
            <!-- PAÍS DO DESTINATÁRIO -- NÃO EDITÁVEL -->

            <!-- <small class="form-text text-muted">Cód. País e não nome</small> -->
          </div>
          <div class="col">
            <!-- TELEFONE DO DESTINATÁRIO -- EDITÁVEL -->
            <label for="postalCode">*Código Postal:</label>
            <div class="input-group mb-2">
              <input id="postalCode" type="text" class="form-control" v-model="postalCode" required />
            </div>
          </div>
          <div class="col">
            <!-- TELEFONE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
            <label for="total">*Contacto Telefónico:</label>
            <div class="input-group mb-2">
              <input id="total" type="text" class="form-control" v-model="phone" required />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <!-- SITE DE ONDE É A ENCOMENDA -- NÃO EDITÁVEL -->
            <label for="total">*Site da encomenda:</label>
            <select class="form-control" v-model="site" required>
              <option value="idivia">idivia</option>
              <option value="kiooli">kiooli</option>
              <option value="marketplace">marketplace</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="shippingCompany">Transportadora:</label>
            <select id="shippingCompany" class="form-select" v-model="shippingCompany">
              <option>Free Shipping</option>
              <option>DHL</option>
              <option>FedEx</option>
              <option>CTT Expresso</option>
              <option>CTT Azul</option>
              <option>CTT</option>
              <option>Outro</option>
            </select>
          </div>
          <div class="col">
            <label for="length">Valor do Envio:</label>
            <input id="length" type="number" class="form-control" v-model="shippingTotal" step=".01" required />
          </div>
          <div class="col">
            <div class="form-group">
              <label for="country">*Moeda:</label>
              <!-- <input
            id="country"
            type="text"
            class="form-control"
            v-model="country"
            style="text-transform: uppercase"
            required
          /> -->
              <v-select
                style="font-size: small"
                required
                :rules="[(v) => !!v]"
                v-model="exchange"
                :options="countries"
                :reduce="(country) => country.coin"
                label="name"
              >
                <template slot="option" slot-scope="option"> {{ option.name }} ({{ option.coin }})</template>
                <template slot="selected-option">
                  {{ this.exchange }}
                </template>
              </v-select>
            </div>
          </div>
          <div class="col">
            <!-- MOEDA DO DESTINATÁRIO -- NÃO EDITÁVEL -->
            <label for="total">Total:</label>
            <input
              id="total"
              type="text"
              class="form-control"
              :placeholder="
                this.orderProducts.reduce((sum, product) => {
                  return sum + Number(product.total);
                }, 0) +
                Number(shippingTotal) -
                Number(discount)
              "
              disabled
            />
          </div>
        </div>
        <h6>Descontos</h6>
        <div class="row">
          <div class="col">
            <label for="length">Valor:</label>
            <input id="length" type="number" class="form-control" v-model="discount" step=".01" />
          </div>
          <div class="col">
            <label for="length">Nome do Cupão:</label>
            <input id="length" type="text" class="form-control" v-model="code_discount" />
          </div>
        </div>
        <br />
        <small class="form-text text-muted">* Preenchimento obrigatório</small>
        <div class="row">
          <div class="col-sm-10"></div>
          <div class="col-sm-2">
            <button class="btn btn-primary mb-2" type="submit">ENVIAR</button>
          </div>
        </div>
      </form>
      <!-- MODAL PARA MOSTRAR O PRODUTO -->
      <b-modal ref="modal_product" id="modal-4" title="Produto" size="lg" hide-footer>
        <div class="card">
          <b-container>
            <b-row align-v="center"
              ><b-col class="left">
                <!-- IMAGEM DO PRODUTO -->
                <img :src="imgProduct" />
              </b-col>
              <b-col>
                <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
                <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
                <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
                <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
                <p><span id="subtitle">Notas Arm.:</span> {{ warehouseProduct }}</p>
                <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
                <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
              </b-col></b-row
            >
          </b-container>
          <button class="btn btn-info" @click="openProdPage()">Editar Produto</button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
/* import { ModelListSelect } from "vue-search-select"; */
/* import { ModelSelect } from "vue-search-select"; */
import 'vue-search-select/dist/VueSearchSelect.css';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    // ModelSelect,
    Loading,
  },
  data() {
    return {
      products: [],
      loading: false,
      currentPage: 1,
      pageSize: 5,
      search: '',
      total: '',
      orderProducts: [],
      firstName: '',
      secondName: '',
      email: '',
      finalAdress: '',
      city: '',
      country: '',
      postalCode: '',
      phone: '',
      state: '',
      shippingCompany: '',
      status: 'processing',
      exchange: '',
      shippingTotal: 0.0,
      shippingLines: [],
      test: '[{"id":11135,"name":"Dr Brown s Options Bottle Glass 150ml Wide Mouth","sku":"7516211","product_id":138150,"variation_id":0,"quantity":1,"tax_class":"","price":"15.77","subtotal":"15.77","subtotal_tax":"0.00","total":"15.77","total_tax":"0.00","taxes":[],"meta":[]}]',
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      countries: [],
      eans: [],
      ean: '',
      productInfo: [],
      feeLines: [],
      code_discount: '',
      discount: 0,
      skuInfo: '',
      previous: '',
      previousID: '',
      site: '',
    };
  },
  methods: {
    async openProdPage() {
      window.open(`https://${window.location.host}/product/${this.idProduct}`, '_blank');
      this.$refs['modal_product'].hide();
    },
    async getOrderInfo() {
      try {
        await this.$store.dispatch('getPreviousOrderInfo', {
          id: this.previousID,
        });
        this.previous = this.getPreviousOrderInfo;
        console.log(this.previous);
        this.firstName = this.previous.name;
        this.secondName = this.previous.last;
        this.email = this.previous.email;
        this.finalAdress = this.previous.address1;
        this.city = this.previous.city;
        this.state = this.previous.state;
        this.country = this.previous.country;
        this.postalCode = this.previous.postcode;
        this.phone = this.previous.phone;
      } catch (err) {
        alert(err);
      }
    },
    async getAllProducts() {
      this.loading = true;
      try {
        await this.$store.dispatch('fetchProducts');
        this.products = this.getProducts.data;
        console.log(this.products);
        /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async nextPage() {
      if (this.currentPage * this.pageSize < this.filterProducts.length) this.currentPage++;
    },
    async prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    async addProduct(id) {
      /* {"id":11135,"name":"Dr Brown s Options Bottle Glass 150ml Wide Mouth","sku":"7516211","product_id":138150,"variation_id":0,"quantity":1,"tax_class":"","price":"15.77","subtotal":"15.77","subtotal_tax":"0.00","total":"15.77","total_tax":"0.00","taxes":[],"meta":[]} */
      let product = this.products.filter((product) => product.id == id);
      console.log(product);
      let name = product[0].name.replace(/'/g, ' ');
      let obj = {
        id: product[0].id,
        name: name,
        sku: product[0].sku,
        product_id: product[0].id,
        variation_id: 0,
        quantity: 1,
        tax_class: '',
        price: '0',
        subtotal: '0',
        subtotal_tax: '0.00',
        total: '0',
        total_tax: '0.00',
        taxes: [],
        meta: [],
      };
      this.orderProducts.push(obj);
      console.log(this.orderProducts);
    },
    async removeProduct(id) {
      const index = this.orderProducts.findIndex((obj) => obj.id == id);
      console.log(index);
      this.orderProducts.splice(index, 1);
    },
    async allCountries() {
      try {
        await this.$store.dispatch('fetchAllCountries');
        // for (let index = 0; index < this.getAllCountries.length; index++) {
        //   const element = this.getAllCountries[index];
        //   this.countries.push(`${element.code}`);
        // }
        for (let index = 0; index < this.getAllCountries.length; index++) {
          const element = this.getAllCountries[index];
          this.countries.push({
            name: element.name,
            code: element.code,
            coin: element.coin,
          });
        }
        console.log(this.countries);
      } catch (err) {
        alert(err);
      }
    },
    async createOrder() {
      this.feeLines = [];
      if (this.country == '' || this.country == undefined) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: 'Por favor selecione um país',
        });
        return;
      }
      if (this.exchange == '' || this.exchange == undefined) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: 'Por favor selecione uma moeda',
        });
        return;
      }
      if (this.exchange)
        this.feeLines.push({
          id: 1,
          name: this.code_discount,
          tax_class: '',
          tax_status: 'taxable',
          total: this.discount,
          total_tax: '0.00',
          taxes: [],
        });
      this.shippingLines = [];
      this.shippingLines.push({
        id: 1,
        method_title: this.shippingCompany,
        method_id: 'flexible_shipping_single',
        total: this.shippingTotal,
        total_tax: 0.0,
        taxes: [],
      });
      console.log(this.shippingLines);
      console.log(this.orderProducts);
      console.log(this.total);
      this.total =
        this.orderProducts.reduce((sum, product) => {
          return sum + Number(product.total);
        }, 0) +
        Number(this.shippingTotal) -
        Number(this.discount);
      try {
        await this.$store.dispatch('addManualOrder', {
          orderProducts: this.orderProducts,
          firstName: this.firstName,
          secondName: this.secondName,
          email: this.email,
          finalAdress: this.finalAdress,
          city: this.city,
          country: this.country,
          postCode: this.postalCode,
          phone: this.phone,
          state: this.state,
          shippingCompany: this.shippingCompany,
          exchange: this.exchange,
          shippingLines: this.shippingLines,
          total: this.total,
          feeLine: this.feeLines,
          site: this.site,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: this.getMessage,
          showConfirmButton: false,
          timer: 1500,
        });
        this.orderProducts = [];
        this.firstName = '';
        this.secondName = '';
        this.email = '';
        this.finalAdress = '';
        this.city = '';
        this.country = '';
        this.postalCode = '';
        this.phone = '';
        this.state = '';
        this.shippingCompany = '';
        this.exchange = '';
        this.shippingLines = '';
        this.shippingTotal = 0.0;
        this.total = '';
        this.feeLines = [];
        this.discount = 0;
        this.code_discount = '';
      } catch (err) {
        this.$swal({
          icon: 'error',
          toast: true,
          position: 'top-end',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    /* OBTER PRODUTOS */
    async getProduct() {
      let ean = this.products.filter((product) => product.ean.includes(this.ean));

      /* console.log(sku) */
      /* CASO O VALOR INSERIDO NÃO SEJA DETECTADO VERIFICAMOS SE FOI UM SKU INTRODUZIDO */
      if (ean.length == 0) {
        ean = this.products.filter((product) => product.sku.includes(this.ean));
      }
      console.log(ean);

      /* CASO ENCONTRE PRODUTO ATRAVÈS DO SKU OU EAN VAMOS ADICIONAR O PRODUTO À LISTA  */
      if (ean.length != 0) {
        /* OBTER DADOS DO PRODUTO INSERIDO */
        this.productInfo = ean;
        /* INSERIR DADOS PARA ILUSTRAR O ÚLTIMO PRODUTO INSERIDO */
        this.nameInfo = this.productInfo[0].name;
        this.eanInfo = this.productInfo[0].ean[0];
        this.skuInfo = this.productInfo[0].sku[0];
        this.img = this.productInfo[0].img;
        let index = this.orderProducts.findIndex((prod) => this.productInfo[0].sku.includes(prod.sku));
        console.log(index);
        if (index == -1) {
          console.log(this.productInfo);
          let obj = {
            id: this.productInfo[0].id_backoffice,
            name: this.productInfo[0].name,
            sku: this.productInfo[0].sku[0],
            product_id: this.productInfo[0].id,
            variation_id: 0,
            quantity: 1,
            tax_class: '',
            price: '0',
            subtotal: '0',
            subtotal_tax: '0.00',
            total: '0',
            total_tax: '0.00',
            taxes: [],
            meta: [],
          };
          this.orderProducts.push(obj);
          this.orderProducts.sort((a, b) => a.name.localeCompare(b.name));
          console.log(this.orderProducts);
        } else {
          this.orderProducts[index].quantity++;
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: 'Não encontramos o Produto :(',
          showConfirmButton: false,
        });
      }
      this.ean = '';
    },
    async passProductData(data) {
      const filterProduct = this.products.filter((product) => product.sku.includes(data));
      console.log(filterProduct);
      this.eanProduct = filterProduct[0].ean;
      this.skuProduct = filterProduct[0].sku;
      this.hsProduct = filterProduct[0].hscode;
      this.warehouseProduct = filterProduct[0].note_warehouse;
      this.notesProduct = filterProduct[0].note_finish;
      this.weightProduct = filterProduct[0].weight;
      this.imgProduct = filterProduct[0].img;
      this.idProduct = filterProduct[0].id_backoffice;
      this.stockProduct = filterProduct[0].stock;
      console.log(filterProduct, data);
    },
    async getEAN() {
      try {
        await this.$store.dispatch('fetchProductsEAN');
        this.eans = this.getProductsEAN;
        console.log(this.eans);
      } catch (err) {
        alert(err);
      }
    },
  },
  computed: {
    ...mapGetters(['getProducts']),
    ...mapGetters(['getMessage']),
    ...mapGetters(['getAllCountries']),
    // ...mapGetters(['getProductsEAN']),
    ...mapGetters(['getPreviousOrderInfo']),

    filterProducts() {
      console.log(this.search);
      return this.products.filter((product) => {
        let filterResult = true;
        if (this.search == '' || this.search.length <= 3) {
          return filterResult;
        } else {
          this.currentPage = 1;
          /* console.log(product.name); */
          if (product.name.toLowerCase().includes(this.search.toLowerCase())) {
            filterResult = product.name.toLowerCase().includes(this.search.toLowerCase());
            console.log(filterResult);
            return filterResult;
          }
          /* if (product.ean.includes(this.search.toLowerCase())) {
            filterResult = product.ean
              .toLowerCase()
              .includes(this.search.toLowerCase());
            return;
          } */
          if (
            (product.sku != null && product.sku.toLowerCase().includes(this.search.toLowerCase())) ||
            this.products.filter((product) => product.ean.includes(this.search)).length != 0
          ) {
            filterResult = product.sku.toLowerCase().includes(this.search.toLowerCase());
            console.log(filterResult);
            if (filterResult == undefined) {
              let ean = this.products.filter((product) => product.ean.includes(this.search));
              // let sku = this.products.filter((x) => x.sku == ean.sku);

              filterResult = ean;
            }
            return filterResult;
          }
        }
      });
    },
    filter() {
      return this.filterProducts.slice().filter((row, index) => {
        this.total = Math.ceil((index + 1) / this.pageSize);
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    },
  },
  async created() {
    this.loading = true;
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    await this.getAllProducts();
    await this.allCountries();
    // await this.getEAN();
    this.loading = false;
  },
};
</script>
<style scoped>
.manualOrder {
  padding: 25px;
  background-color: #fff1f1;
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: visible;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#prodImg {
  max-width: 60px;
}
td input {
  width: 50%;
}
#newOrder {
  background-color: white;
  border-radius: 5px;
}
h5 {
  text-align: right;
  margin-right: 7%;
}
img {
  max-width: 100%;
  border-radius: 18px;
}
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
</style>
