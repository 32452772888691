<template>
  <div>
    <ManualOrders></ManualOrders>
  </div>
</template>

<script>
import ManualOrders from '@/components/ManualOrdersForm.vue';
export default {
  components: {
    ManualOrders,
  },
};
</script>
